import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/table-row-mutation.scss';

/**
 * When in list view, display the mutation of an item.
 * @param {*} param0
 * @returns
 */
const TableRowMutation = ({ mutations, latestOperationId }) => {
    if (mutations) {
        if (mutations.created && mutations.created.operationId === latestOperationId) {
            return (
                <div className="table-row-mutations table-row-mutations--created">
                    <Icon fontSize="inherit">add_circle_outline</Icon> created
                </div>
            );
        } else if (mutations.updated && mutations.updated.operationId === latestOperationId) {
            return (
                <div className="table-row-mutations table-row-mutations--updated">
                    <Icon fontSize="inherit">refresh</Icon> updated
                </div>
            );
        }
    }

    return null;
};

export default TableRowMutation;
